import React, { useEffect } from "react";
import Layout from "@components/layout/layout";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import "@styles/styles.scss";
import image3 from "@images/baby-hichair.jpeg";

import "aos/dist/aos.css"; // You can also use <link> for styles

// todo images
const HowItWorksPage = () => {
  let AOS;
  useEffect(() => {
    /**
     * Server-side rendering does not provide the 'document' object
     * therefore this import is required either in useEffect or componentDidMount as they
     * are exclusively executed on a client
     */
    const AOS = require("aos");
    AOS.init({
      once: false,
    });
  }, []);

  useEffect(() => {
    if (AOS) {
      AOS.refresh();
    }
  });

  return (
    <Layout pageTitle="How It Works">
      <div style={{ height: "10vh" }}></div>
      <section className="section">
        <div className="container">
          <div className="columns is-multiline ">
            <div className="column is-12 has-vertically-aligned-content">
              <div className="container">
                <p className="is-size-3">How does our service work?</p>
              </div>
            </div>
          </div>
          {/***************************/}
          <div className="columns is-multiline">
            <div className="column is-12 has-vertically-aligned-content">
              <div className="container">
                <p>
                  <span className="has-text-weight-bold is-size-5">
                    1. Apply Online
                  </span>{" "}
                  - Fill out the registration form and pay the new family
                  registration fee using our{" "}
                  <Link to="/applications/family">online application</Link>{" "}
                  page.
                </p>

                <br />

                <p>
                  <span className="has-text-weight-bold is-size-5">
                    2. Interview
                  </span>{" "}
                  - We will reach out to you for an initial interview (virtual
                  only at this time) so we can meet each other, evaluate your
                  needs, answer any questions, set expectations, sign agency
                  / family contracts, and set ourselves up for a long and successful
                  working relationship.
                </p>

                <br />

                <p>
                  <span className="has-text-weight-bold is-size-5">
                    3. The Search Begins
                  </span>{" "}
                  - A fee for each new search is due to officially begin the
                  process of finding your nanny candidates. Sit back and relax
                  while we fully vet, interview, reference check, and create a
                  profile for each candidate prior to sending them to you.
                  Payment of this fee is confirmation that you’re actively
                  seeking to employ a nanny into your household and is due for
                  each search for a new job description. May be partially
                  refundable if we can not deliver (1+) candidate in agreed upon
                  time frame.
                </p>

                <br />

                <p>
                  <span className="has-text-weight-bold is-size-5">
                    4. Interview Candidate(s)
                  </span>{" "}
                  - Review our available nanny profiles and we will assist you
                  in setting up as many virtual interviews as you’d like. We
                  recommend an in-person, yet socially distant interview with
                  any candidate you hope to present with a job offer. Small fee
                  for each virtual or in person interview (applied toward your
                  placement dues).
                </p>

                <br />

                <p>
                  <span className="has-text-weight-bold is-size-5">
                    5. Job Offer
                  </span>{" "}
                  - Upon request, we can help you create a job offer. If
                  accepted by Nanny, we will move on to the following steps.
                </p>

                <br />

                <p>
                  <span className="has-text-weight-bold is-size-5">
                    6. Placement Dues
                  </span>{" "}
                  - As soon as Nanny accepts your job offer, you will be
                  invoiced for your placement dues.
                </p>

                <br />

                <p>
                  <span className="has-text-weight-bold is-size-5">
                    7. Nanny/Family Contract Negotiations and Signing
                  </span>{" "}
                  - We strongly suggest that you create or utilize a contract
                  with any Houshold Employee/Nanny. Upon request, we can work
                  with you to quickly create a Custom Nanny/Family contract.
                </p>

                <br />

                <p>
                  <span className="has-text-weight-bold is-size-5">
                    8. Nanny Starts Work!
                  </span>{" "}
                  - We are available for any guidance, questions, and support as
                  you navigate your new working relationship with your household
                  employee!
                </p>

                <br />
              </div>
            </div>
            <div className="column is-12 has-vertically-aligned-content">
              <div className="section-color dark-info-box" id="dark-info-box">
                <div className="container">
                  <div
                    className="column is-12 has-text-centered"
                    data-aos="fade-in"
                    data-aos-easing="linear"
                  >
                    <FontAwesomeIcon icon={faCalendarAlt} size="3x"/>
                  </div>

                  <div
                    className="column is-12 intro"
                    data-aos="fade-in"
                    data-aos-easing="linear"
                  >
                    <h1 className="is-size-3 has-text-centered">TIMEFRAME</h1>
                  </div>
                  <div className="columns">
                    <div
                      className="column has-text-centered"
                      data-aos="fade-in"
                      data-aos-easing="linear"
                    >
                      <p>
                        In some cases we will already have your ideal nanny in
                        our database. We have been a top rated babysitting
                        service since 2012. We currently have a list of
                        potential nannies we know are ready to be placed. Our
                        contact list of 500+ care providers is growing daily. We
                        will work with you on your time frame, and be honest
                        about placement potential, estimated time frame, and
                        more. Most nanny agencies say to allow an average of 4-6
                        weeks for your ideal placement - we hope to do it more
                        quickly! However, the pickier we both are, the longer it
                        will take! Now is always the perfect time to start
                        searching for your ideal nanny, even and especially if
                        you don’t need one right away! :){" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-12">
              <img src={image3} alt="" />
            </div>
          </div>

          {/***************************/}
        </div>
      </section>
    </Layout>
  );
};

export default HowItWorksPage;

{
  /* <div className="column is-6 section-color dark-info-box">
              <div
                className="container has-text-centered"
                data-aos="fade-in"
                data-aos-easing="linear"
              >
                <p className="is-size-4">Referral Promos</p>
              </div>
              <br></br>
              <ul>
                <li>
                  <div data-aos="fade-in" data-aos-easing="linear">
                    <p className="has-text-weight-bold">
                      Refer a Nanny We Ultimately Place:
                    </p>
                  </div>
                  <div data-aos="fade-in" data-aos-easing="linear">
                    <p className="">$25 part time/temp / $50 full time</p>
                  </div>
                </li>
                <br></br>
                <li>
                  <div data-aos="fade-in" data-aos-easing="linear">
                    <p className="has-text-weight-bold">
                      Refer a Family Who Ultimately Hires:
                    </p>
                  </div>
                  <div data-aos="fade-in" data-aos-easing="linear">
                    <p className="">$50 part time/temp / $100 full time</p>
                  </div>
                </li>
              </ul>
              <br></br>
              <div data-aos="fade-in" data-aos-easing="linear">
                <p className="is-size-7">
                  **PAID IN GIFT CARDS TO YOUR CHOICE OF LOCAL RESTAURANT /
                  SHOP**
                </p>
              </div>
            </div> */
}
